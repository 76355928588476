@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

@font-face {
  font-family: "Mangerica";
  src: local("Mangerica"),
    url(./static/fonts/Mangerica-Light.otf) format("opentype");
}

@font-face {
  font-family: "Mangerica-Medium";
  src: local("Mangerica-Medium"),
    url(./static/fonts/Mangerica-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Mangerica-Bold";
  src: local("Mangerica-Bold"),
    url(./static/fonts/Mangerica-Bold.otf) format("opentype");
}

@tailwind base;
@tailwind components;

@tailwind utilities;

* {
  @apply box-border;
}

.input-wrapper {
  @apply my-3 flex flex-col mx-auto md:mx-6 w-4/5 relative;
}

.text-input {
  @apply rounded-lg bg-white py-2 px-4 border-b-2 border-transparent focus:bg-blue-50 focus:bg-opacity-60 border-gray-200 focus:border-secondary focus:outline-none placeholder-gray-300 focus:placeholder-blue-200 text-gray-800 shadow-main;
}

.input-label {
  @apply text-gray-800 mb-1;
}

.plans-label {
  @apply text-center font-header w-5/6 text-gray-800 px-6 mx-auto text-xl border-t border-gray-300 pt-8 pb-6;
}

.plan-card {
  @apply input-label  w-4/5 mx-auto md:mx-6 bg-white p-5 pb-16 rounded-xl flex flex-col border-2 mt-4 border-transparent cursor-pointer hover:bg-opacity-60 hover:bg-blue-50 content-between shadow-main;
}

.active-card {
  @apply border-secondary bg-blue-50 bg-opacity-50;
}

.active-label {
  @apply text-secondary;
}

.validation-error-message {
  @apply text-red-500 text-sm pt-2;
}

.submit-button {
  @apply mt-4 py-3 px-7 opacity-100 hover:opacity-90 bg-primary text-white text-lg rounded-xl shadow transform transition hover:-translate-y-0.5;
}

.inputs-wrapper {
  @apply flex flex-col md:flex-row items-start justify-between;
}
